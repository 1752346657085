/** @module NmarketCatalogue
 *  @since 2022.01.04, 20:38
 *  @changed 2022.02.11, 20:58
 */

import React from 'react'
import {
  Box,
  Text,
} from '@chakra-ui/react'
import classnames from 'classnames'
import { LocationHashEnum } from '@config/links'
import PageSection from '@ui-elements/PageSection'
import Wrapper from '@components/Wrapper'
import PageLink from '@ui-support/PageLink'
import PageSectionHeader from '@ui-elements/PageSectionHeader'

import styles from './NmarketCatalogue.module.less'

const externalLink = 'https://march.nmarket.pro/catalog'
const catalogueLinkTemplate = 'https://march.nmarket.pro/catalog/?isFrame=true&frameListSize='
const defaultItemscount = 10
const catalogueLinkDefault = catalogueLinkTemplate + String(defaultItemscount)

// const defaultIframeHeight = 800
const defaultIframeItems = 10
const approxHeights = {
  item: {
    0: 600,
    400: 610,
    450: 650,
    500: 670,
    510: 700,
    600: 725,
    640: 750,
    680: 760,
    700: 790,
    752: 300, // 590 / 2,
    768: 790, // 590 / 2,
    800: 300, // 600 / 2,
    // 737: 290, // 540 / 2,
    // 737: 290, // 540 / 2,
    // 850: 280, // 275, // 520 / 2
    1264: 235,
  },
  header: {
    0: 70,
    400: 75,
    1264: 196,
  },
  footer: {
    0: 120,
    1264: 70,
  },
}

/* // DEBUG Test api mocking (need to substitute `origin` or `referer` headers, impossible in browsers.
 * function testApi() {
 *   const apiHostUrl = 'https://march.nmarket.pro'
 *   const url = 'https://ecatalog.nmarket.pro/webapi/all-complexes?page=1&pageSize=1000'
 *   const originFields = {
 *     origin: apiHostUrl,
 *     referer: apiHostUrl,
 *     referrer: apiHostUrl,
 *   }
 *   const headers = {
 *     Accept: 'application/json',
 *     ...originFields,
 *   }
 *   const fetchOptions = {
 *     // mode: 'no-cors' as RequestMode, // eslint-disable-line no-undef
 *     ...originFields,
 *     headers,
 *   }
 *   try {
 *     console.log('@:NmarketCatalogue:testApi: fetch start', {
 *       url,
 *       fetchOptions,
 *       headers,
 *     })
 *     debugger
 *     fetch(url, fetchOptions)
 *       .then((res) => res.json())
 *       .then((data) => {
 *         console.log('@:NmarketCatalogue:testApi: fetch done', {
 *           data,
 *         })
 *         debugger
 *       })
 *       .catch((error) => {
 *         // eslint-disable-next-line no-console
 *         console.error('@:NmarketCatalogue:testApi: fetch catch', {
 *           error,
 *           // Source params...
 *           url,
 *           fetchOptions,
 *         })
 *         // eslint-disable-next-line no-debugger
 *         debugger
 *       })
 *   }
 *   catch(error) {
 *     console.error('@:NmarketCatalogue:testApi: catch', {
 *       error,
 *       url,
 *       fetchOptions,
 *     })
 *     debugger
 *   }
 * }
 */

/* Calculate catalogue height and items count depends on widow width & height */
function getIframeHeight(windowWidth /* , _windowHeight */) {
  // const iframeHeight = defaultIframeHeight
  const iframeItems = defaultIframeItems
  const { item, header, footer } = approxHeights
  const itemHeight = Object.entries(item).reduce((result, [cmpWidth, setWidth]) => {
    const cmp = Number(cmpWidth)
    const newResult = !result || cmp <= windowWidth ? setWidth : result
    return newResult
  }, 0)
  const headerHeight = Object.entries(header).reduce((result, [cmpWidth, setWidth]) => {
    return Number(cmpWidth) <= windowWidth ? setWidth : result
  }, 0)
  const footerHeight = Object.entries(footer).reduceRight((result, [cmpWidth, setWidth]) => {
    return Number(cmpWidth) <= windowWidth ? setWidth : result
  }, 0)
  const resultHeight = headerHeight + footerHeight + itemHeight * iframeItems
  // console.log('@:NmarketCatalogue:getIframeHeight', {
  //   windowWidth,
  //   resultHeight,
  //   itemHeight,
  //   headerHeight,
  //   footerHeight,
  //   item,
  //   header,
  //   footer,
  // })
  return resultHeight
}

function updateIframeHeight(node) {
  if (node && typeof window !== 'undefined' && window.innerWidth) {
    const windowWidth = window.innerWidth
    // const windowHeight = window && window.innerHeight
    const iframeHeight = getIframeHeight(windowWidth)
    // console.log('@:NmarketCatalogue:updateIframeHeight', {
    //   node,
    //   windowWidth,
    //   iframeHeight,
    //   // iframeItems,
    //   // docWidth,
    // })
    node.style.height = isNaN(iframeHeight) ? iframeHeight : String(iframeHeight) + 'px'
  }
}

interface TNmarketCatalogueProps {
  className?: string
}

function NmarketCatalogue(props: TNmarketCatalogueProps) {
  // NOTE: Here was some experiments with Tina CMS
  const [titleText /* , setTitleText */] = React.useState('Каталог недвижимости')
  const [introText /* , setIntroText */] = React.useState(
    'Мы собрали в один каталог самые популярные ЖК Москвы и Московской области. Если всё же не нашли подходящего варианта, звоните! Мы найдем квартиру под Ваш запрос!'
  )
  const iframeRef = React.useRef(null)
  const [inited, setInited] = React.useState(false)
  // const { firestore } = useStore(({ firestore }) => ({ firestore })) // Try to fetch text data from database...
  React.useEffect(() => {
    /* // Try to fetch text data from database...
     * firestore.collection('SiteTexts').doc('NmarketCatalogue').get().then((res) => {
     *   const data = res && res.data() || {}
     *   const { Title, Intro } = data
     *   // console.log('@:NmarketCatalogue:useEffect', {
     *   //   data,
     *   //   Title,
     *   //   Intro,
     *   // })
     *   Intro && setIntroText(Intro)
     *   Title && setTitleText(Title)
     * })
     */
    const node = iframeRef && iframeRef.current // eslint-disable-line
    function onResize() {
      updateIframeHeight(node)
    }
    // const iframeHeight = defaultIframeHeight
    // const iframeItems = defaultIframeItems
    const useIframe = config.build.isBrowser // && !config.build.isDev
    if (useIframe && node) {
      updateIframeHeight(node)
      node.src = catalogueLinkDefault
      window.addEventListener('resize', onResize)
      setInited(true)
    }
    // testApi() // DEBUG
    // TODO?
    // NOTE: Iframe's internals can be accessed on the same-origin iframes only.
    // @see https://stackoverflow.com/questions/25098021/securityerror-blocked-a-frame-with-origin-from-accessing-a-cross-origin-frame
    // const nodeDocument = node && node.contentWindow && node.contentWindow.document
    return () => {
      if (useIframe) {
        window.removeEventListener('resize', onResize)
      }
    }
  }, [iframeRef])
  const { className } = props
  const isDebug = config.build.isDevServer
  const innerContent = isDebug ? (
    <>Debug Content</>
  ) : (
    <iframe
      id="NmarketCatalogue"
      ref={iframeRef}
      // scrolling="no"
      className={classnames(styles.Iframe)}
      src={catalogueLinkDefault}
    >
      {/* // TODO: Use preload content
      */}
    </iframe>
  )
  return (
    <PageSection
      className={classnames(className, styles.root, inited && styles.Inited)}
      id={LocationHashEnum.LocationCatalogue}
    >
      <Wrapper flexDirection="column" alignItems="center">
        <PageSectionHeader title={titleText} description={introText} titleSize="large" />
        {/*
        <PageSectionHeader title={titleText} />
        Здесь вы можете самостоятельно подобрать квартиру, апартаменты или таунхаус для&nbsp;покупки.
        </PageSectionHeader>
        */}
        <Box className={classnames(styles.Preload)}>
          <Text align="center">
            Перейти в каталог{' '}
            <PageLink isExternal href={externalLink}>
              по ссылке
            </PageLink>
            .
          </Text>
          {/*
          <Heading
            as="h3"
            // textTransform="uppercase"
            // fontSize={['3xl', '4xl', '5xl']}
            fontWeight="normal"
            m="20px"
            // textAlign="center"
          >
            Каталог недвижимости
          </Heading>
          <Button colorScheme="primary" href="">Перейти в каталог</Button>
          */}
        </Box>
        {innerContent}
        {/*
        <iframe
          id="NmarketCatalogue"
          ref={iframeRef}
          // scrolling="no"
          className={classnames(styles.Iframe)}
          src={catalogueLinkDefault}
        />
        */}
      </Wrapper>
    </PageSection>
  )
}

export default NmarketCatalogue
