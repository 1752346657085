/** @module MainPage
 *  @since 2022.01.03, 17:21
 *  @changed 2022.01.29, 00:26
 */

import React from 'react'
// import { Box, Heading } from '@chakra-ui/react'
import * as siteConfig from '@config/site'
// import TopSplash from '@components/TopSplash/TopSplash'
import GenericPageLayout from '@layout/GenericPageLayout'
import NmarketCatalogue from '@components/NmarketCatalogue'
// import OurProjects from '@components/OurProjects'
// import OrderConsultationFormSection from '@forms/OrderConsultationForm/OrderConsultationFormSection'
// import BuyApartmentFormSection from '@forms/BuyApartmentForm/BuyApartmentFormSection'
// import SellApartmentFormSection from '@forms/SellApartmentForm/SellApartmentFormSection'
// import OurAdvantages from '@components/OurAdvantages'
// import FAQ from '@components/FAQ'
// import About from '@components/About'
// import OurServices from '@components/OurServices'
// import OurCommand from '@components/OurCommand'

/* // UNUSED: Demo
 * function Demo() {
 *   return (
 *     <Box>
 *       <Heading
 *         // className={styles.Title}
 *         as="h3"
 *         // textTransform="uppercase"
 *         // fontSize="5xl"
 *         fontSize={['3xl', '4xl', '5xl', '5xl', '5xl']}
 *         fontWeight="normal"
 *         alignSelf="flex-start"
 *         textAlign="center"
 *         whiteSpace="nowrap"
 *       >
 *         Extra large text string for wrap testing
 *       </Heading>
 *     </Box>
 *   )
 * }
 */

function MainPage(/* props */) {
  // NOTE: Get props from nextjs (as `pageProps`)
  const { title } = siteConfig
  return (
    <GenericPageLayout title={title}>
      <NmarketCatalogue />
      {/*
      <OrderConsultationFormSection />
      <OurProjects />
      <BuyApartmentFormSection />
      <SellApartmentFormSection />
      <OurAdvantages />
      <About />
      <OurServices />
      <OurCommand />
      <FAQ />
      <Divider mt="24" />
      <FooterWithForm />
      */}
    </GenericPageLayout>
  )
}

/* // UNUSED: getStaticProps
 * export async function getStaticProps(context) {
 *   console.log('@:MainPage:getStaticProps', { context })
 *   debugger
 *   return {
 *     props: {
 *       test: 'TEST_getStaticProps',
 *     },
 *   }
 * }
 */

export default MainPage
